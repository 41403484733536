import React, { useState } from "react";
import { Badge, Button, Col, Input, Row, Spinner, Table, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from "reactstrap";
import CustomInput from "./CustomInput";
import { _fetchApi, _postApi } from "../../redux/action/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { formatNumber } from "../../utils";

export default function NamesAdjustment() {
  const [refNo, setRefNo] = useState("");
  const { user } = useSelector((s) => s.auth);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingh, setLoadinghan] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    _postApi(
      `/name-transactions?reference_number=${refNo}`,
      {},
      (resp) => {
        setLoading(false);
        if (resp.success) {
          setTransactionData(resp.data);
          setModalVisible(true);
        }
      },
      (err) => {
        console.error("API Error:", err);
        setLoading(false);
      }
    );
  };
  

  const handleUpdateTable = () => {
    setLoadinghan(true);
    _postApi(
      `/name-transactions?reference_number=${refNo}`,
      {
        tax_payer: transactionData[0]?.tax_payer,
        address: transactionData[0]?.address,
        phone: transactionData[0]?.phone,
      },
      (res) => {
        setLoadinghan(false);
        if (res.success) {
          toast.success("Successfully updated the record.");
          setModalVisible(false);
          setTransactionData([]);
          setRefNo("");
        }
      },
      (err) => {
        setLoadinghan(false);
        console.error("Update Error:", err);
        toast.error("Error occurred while updating the record.");
      }
    );
  };
  


  const handleChange = (field, value, index) => {
    const updatedData = [...transactionData];
    updatedData[index][field] = value;
    setTransactionData(updatedData);
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <CustomInput
            label="Reference Id"
            type="text"
            name="refNo"
            placeholder="Search Reference Id"
            value={refNo}
            onChange={(e) => setRefNo(e.target.value)}
          />
        </Col>
        <Col md={6}>
          <Button
            color="dark"
            className="col-md-6"
            style={{ marginTop: 30 }}
            disabled={loading}
            onClick={handleSearch}
          >
            {loading ? (
              <>
                <Spinner size="sm" color="light" style={{ marginRight: "5px" }} />
                Searching...
              </>
            ) : (
              "Search"
            )}
          </Button>
        </Col>
      </Row>

      <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)}>
        <ModalHeader toggle={() => setModalVisible(false)}>Name Correction</ModalHeader>
        <ModalBody>
          <Form>
            {transactionData.map((data, index) => (
              <React.Fragment key={index}>
                <FormGroup>
                  <Label for="tax_payer">Tax Payer</Label>
                  <Input
                    type="text"
                    id="tax_payer"
                    value={data.tax_payer}
                    onChange={(e) => handleChange("tax_payer", e.target.value, index)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    id="address"
                    value={data.address}
                    onChange={(e) => handleChange("address", e.target.value, index)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone number">Phone</Label>
                  <Input
                    type="text"
                    id="phone"
                    value={data.phone}
                    onChange={(e) => handleChange("phone", e.target.value, index)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Badge color="blue">{data.status}</Badge>
                </FormGroup>
              </React.Fragment>
            ))}
            <Button
              color="dark"
              style={{ marginTop: 20 }}
              disabled={loadingh}
              onClick={handleUpdateTable}
            >
              {loadingh ? (
                <>
                  <Spinner size="sm" color="light" style={{ marginRight: "5px" }} />
                  Updating...
                </>
              ) : (
                "Update"
              )}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}
