import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupText,
  Form,
  Spinner,
  ButtonGroup,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { _fetchApi, _postApi } from "../../redux/action/api";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import moment from "moment";
import toast from "react-hot-toast";
import { useQuery } from "../../utils";
import { useSelector } from "react-redux";
import AuditLogsTable from "./AuditLogsTable";
import CustomModal from "../../components/UI/CustomModal";
import AddAccout from "../AddAccount";
// import FilterAuditLogs from "./FilterAuditLogs";

export default function AuditLogs() {
  const year = moment().subtract(1, "year").format("YYYY");
  const date = moment().format("YYYY-MM-DD");
  const q = useQuery();
  const { user } = useSelector((s) => s.auth);
  const _form = {
    query_type: 'select',
    phone: "",
    email: "",
    username: "",
    mda_name: "",
    mda_code: "",
    username: "",
    taxID: "",
    date_from: date,
    date_to:date,
    limit:100,
    offset:0,
  };
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);
  const [getLoading, setGetLoading] = useState(false);
  const [data, setData] = useState(false);

  const handleChanges = ({target:{name, value}}) => {
    setForm((p) => ({ ...p, [name]: value }));
  }

  useEffect(()=>{
    _fetchApi(
      `/audit/logs?query_type=select-all&date_from=${form.date_from}&date_to=${form.date_to}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setData(res.data);
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Error occured");
        console.log(err);
      }
    );
  },[0])
  //   r'^[1-9.,]+$'
  const [form, setForm] = useState(_form);
  const FilterAuditLogs = () => {

    return (
      <Row>
        {/* {JSON.stringify(form)} */}
        <Col md={6} className="text-right">
          <Label>TaxID</Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            name="taxID"
            value={form.taxID}
            onChange={handleChanges}
            required={true}
          />
        </Col>
        <Col md={6}  className="text-right">
          <Label>Username</Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            // required={true}
            required={form.name}
            type="text"
            name="name"
            value={form.name}
            onChange={handleChanges}
          />
        </Col>
        <Col md={6} className="text-right">
          <Label>Phone</Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            type="text"
            name="phone"
            value={form.phone}
            onChange={handleChanges}
          />
        </Col>
        {/* <Col md={6} className="p-1">
          <Label>Route</Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            type="text"
            name="route"
            value={form.route}
            onChange={handleChanges}
          />
        </Col> */}
        <Col md={6} className="text-right">
          <Label>Parameters</Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            type="text"
            name="parameter"
            value={form.parameter}
            onChange={handleChanges}
          />
        </Col>
        <Col md={6}  className="text-right">
          <Label>
            Date from
          </Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            type="date"
            name="date_from"
            validate={form.date_from}
          />
        </Col>
        <Col md={6} className="text-right">
          <Label>
            Date to
          </Label>
        </Col>
        <Col md={6} className="p-1">
          <Input
            type="date"
            name="date_to"
            validate={form.date_to}
          />
        </Col>
      </Row>
    )
  }
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);

  // const toggle = () => {
  //   setShow(!show);
  // };
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  // let val = _data?.filter((item) => item.value === form.income_source);

  const handleSubmit = (e) => {
    e.preventDefault();
    
      setLoading(true);
      _postApi(
        "/audit/logs",
        form,
        (res) => {
          setLoading(false);
          if (res.success) {
            setData(res.data);
            setForm(_form);
            toast.success("Submitted");
            toggleModal();
            // history.goBack();
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Error occured");
          console.log(err);
        }
      );
  };





  return (
    <div className="m-3">
      <Card
        className=""
        style={{ overflow: "scroll", height: "90vh" }}
      >
        <CardHeader className="bg-primary  d-flex justify-content-between">
          <Button
            onClick={() => {
              history.goBack();
            }}
            size="sm"
          >
            Back
          </Button>

          <h4 style={{ color: "#fff" }} className="">
            AUDIT LOGS
          </h4>
          <div></div>
        </CardHeader>
        {/* {JSON.stringify(form)} */}

        <CardBody className="">
          <Form>
            <Row>
            
              <Col>
               <Button onClick={()=>toggleModal()}>Filter</Button>
              </Col>
              <Col>
               
              </Col>
              <Col md={12}>
               
              </Col>
            </Row>
          </Form>
          <Row>
          <AuditLogsTable data={data} />
          </Row>
        </CardBody>
      </Card>
      <CustomModal toggle={toggleModal} centered isOpen={showDefault} size="md">
        <div style={{ marginTop: "-60px", padding: "0px 20px" }}>
          
          <Card>
            <CardBody>
              <FilterAuditLogs data={data}  />
            </CardBody>
          </Card>
          <div className="modal-footer">


          <Button onClick={()=>handleSubmit()}>Submit</Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
