import moment from 'moment';
import React from 'react';
import { Spinner, Table } from 'reactstrap';
import { Routes } from '../../routes';
import { useHistory } from 'react-router-dom';

// Sample data (this should be replaced with actual data fetched from your API)
// const auditLogs = [
//     {
//         id: 1,
//         username: 'Brainstorm.ng',
//         taxID: 13,
//         phone: '09124611644',
//         email: 'hello@brainstorm.ng',
//         mda_name: '',
//         method: 'GET',
//         route: '/search-tax-clearance?status=initiated&search=',
//         action: 'View',
//         timestamp: '2024-10-23 11:36:21',
//         ip: '::1',
//         sector: null,
//         parameters: JSON.stringify({ status: 'initiated', search: '', in_from: '2024-10-23' }),
//         createdAt: '2024-10-23 11:36:21',
//     },
//     {
//         id: 2,
//         username: 'Brainstorm.ng',
//         taxID: 13,
//         phone: '09124611644',
//         email: 'hello@brainstorm.ng',
//         mda_name: '',
//         method: 'GET',
//         route: '/get/tax-clearance?query_type=select-status&status=initiated',
//         action: 'View',
//         timestamp: '2024-10-23 11:36:23',
//         ip: '::1',
//         sector: null,
//         parameters: JSON.stringify({ query_type: 'select-status', status: 'initiated' }),
//         createdAt: '2024-10-23 11:36:23',
//     },
//     // Add more rows as needed
// ];

const AuditLogsTable = ({data=[]}) => {
    const history = useHistory()
    return data.length ? (
        <div className="container mt-5">
            <h2>Audit Logs</h2>
            <Table striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>Tax ID</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>MDA Name</th>
                        <th>Params</th>
                        <th>Action</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((log,idx) => (
                        <tr style={{cursor:'pointer'}} onClick={()=>history.push(`${Routes.AuditLogsView.path}?kirmas_id=${log.taxID}`)} key={idx}>
                            <td>{idx+1}</td>
                            <td>{log.username}</td>
                            <td>{log.taxID}</td>
                            <td>{log.phone}</td>
                            <td>{log.email}</td>
                            <td>{log.mda_name}</td>
                            <td>{log.parameters}</td>
                            <td>{moment(log.timestamp).format('DD/MM/YYYY HH:mm:ss A')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    ):<div className="text-center"><Spinner /></div>
};

export default AuditLogsTable;
